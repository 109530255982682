import React from "react";

// Images
import Material3_web from "../assets/img/Materialer3_web.jpg";
import Colors from "../assets/img/colors.jpg";

const Header = (props) => {
  return (
    <>
      <img
        className="header_img-front"
        src={Material3_web}
        title="montesorri"
        alt="montessori"
      />
      <img
        className="header_img-mobile"
        src={Colors}
        title="montesorri"
        alt="montessori"
      />
    </>
  );
};

export default Header;
