import React from "react";

// Images
import Material1 from "../assets/img/Material1_web.jpg";

const AboutHeader = (props) => {
  return (
    <>
      <img
        className="header_img"
        src={Material1}
        title="Montesorri material"
        alt="Montessori material"
      />
    </>
  );
};

export default AboutHeader;
